import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import styledMap from 'styled-map';
import Arrow from 'icons/Arrow';
import fluidRange from 'utils/fluidRange';
import { minWidth } from 'utils/breakpoints';

const Wrapper = styled.div`
  color: ${styledMap('color', {
    black: `var(--black)`,
    white: `var(--white)`,
    solidBlack: `var(--white)`,
  })};
  display: inline-flex;
  border: 1px solid;
  border-color: ${styledMap('color', {
    black: `var(--black)`,
    white: `var(--white)`,
    solidBlack: `var(--black)`,
    blur: 'transparent',
    offBlack: 'var(--keyline)',
  })};
  font-weight: 500;
  border-radius: 1em;
  align-items: center;
  text-align: center;
  cursor: pointer;
  line-height: 1.5em;
  transition: color 0.2s ease, background 0.2s ease;
  will-change: color, background;
  padding: 0.1em 0.6em;
  ${minWidth('mobileLandscape')} {
    padding: 0.2em 0.8em;
  },
  margin-right: 1px;
  background-color: ${styledMap('color', {
    solidBlack: `black`,
    blur: `rgba(0, 0, 0, 0.4)`,
    offBlack: 'var(--off-black)',
    default: 'none',
  })};
  white-space: nowrap;
  backdrop-filter: ${styledMap('color', {
    blur: `blur(3px)`,
    default: 'none',
  })};
  &:hover {
    ${({ color }) =>
      color === 'white' &&
      css`
        mix-blend-mode: screen;
      `}
    color: ${styledMap('color', {
      black: `var(--white)`,
      white: `var(--black)`,
      offBlack: 'var(--black)',
      blur: 'var(--white)',
    })};
    backdrop-filter: ${styledMap('color', {
      blur: `blur(6px)`,
      default: 'none',
    })};
    background-color: ${styledMap('color', {
      black: `var(--black)`,
      white: `var(--white)`,
      solidBlack: `var(--white)`,
      blur: `rgba(0, 0, 0, 0.2)`,
      offBlack: 'var(--white)',
    })};
    border-color: ${styledMap('color', {
      offBlack: 'var(--white)',
      blur: 'none',
    })};
  }
  &:active {
    transform: scale(0.975);
  }
  &:focus:not(:focus-visible) {
    outline: none;
  }
  ${styledMap('size', {
    default: 'inherit',
    medium: fluidRange({
      prop: 'font-size',
      fromSize: '12px',
      toSize: '14px',
    }),
    large: fluidRange({
      prop: 'font-size',
      fromSize: '20px',
      toSize: '30px',
    }),
  })};
`;

const ArrowIcon = styled(Arrow)`
  height: 1em;
  margin-left: 0.5em;
  stroke-width: 2px;
`;

const getElement = (href, onClick, to, type) => {
  if (href) return 'a';
  if (onClick || type) return 'button';
  if (to) return Link;
  return 'div';
};

export default function Button({
  className,
  color,
  arrow,
  children,
  href,
  size,
  onClick,
  target,
  type,
  to,
}) {
  return (
    <Wrapper
      color={color}
      size={size}
      as={getElement(href, onClick, to, type)}
      className={className}
      href={href}
      onClick={onClick}
      target={target}
      to={to}
      type={type}
    >
      {children}
      {arrow && <ArrowIcon />}
    </Wrapper>
  );
}

Button.propTypes = {
  arrow: PropTypes.bool,
  color: PropTypes.oneOf(['solidBlack', 'black', 'white', 'blur', 'offBlack']),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  target: PropTypes.bool,
  to: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.oneOf(['medium', 'large']),
};

Button.defaultProps = {
  color: 'black',
  size: 'medium',
};
