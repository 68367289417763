import React from 'react';
import { Layout as PageLayout } from './src/components/common/Layout';

const wrapPageElement = ({ element, props }) => {
  if (props.path.includes('deck')) {
    return element;
  }
  return <PageLayout {...props}>{element}</PageLayout>;
};

export default wrapPageElement;
