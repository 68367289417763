import { keyframes } from 'styled-components';

export const fadeIn = keyframes`
 0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const fadeInOut = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const slideIn = keyframes`
  0% {
    transform: translateX(500%);
  }
  50% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-500%);
  }
`;
export const slideOut = keyframes`
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
`;
