import styled from 'styled-components';
import between from 'utils/between';

export default styled.div`
  display: grid;
  grid-column-gap: ${between('10px', '20px')};
  grid-template-columns:
    [full-bleed-start] 0 [main-start] repeat(12, [col] 1fr)
    [main-end] 0 [full-bleed-end];
`;
