import React from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence } from 'framer-motion';
import Intro from '../pages/home/Intro';
import Header from './Header';

export const Layout = ({ children, path }) => (
  <>
    <Intro path={path} />
    <Header currentPath={path} />
    <AnimatePresence mode="wait" initial={false}>
      {children}
    </AnimatePresence>
  </>
);
Layout.propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.string.isRequired,
};
