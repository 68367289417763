module.exports = {
  breakpoints: {
    mobileSmall: 320,
    mobileLandscape: 600,
    tabletPortrait: 800,
    tabletLandscape: 1100,
    laptop: 1400,
    desktop: 1920,
  },
  animation: {
    duration: 0.8,
    delay: 0.4,
  },
  colors: {
    white: '#fff',
    black: '#000',
    offBlack: '#181818',
    keyline: '#49494A',
    darkGrey: '#929292',
    lightGrey: '#F7F7F7',
    onWhiteGrey: '#5E5E5E',
    greyGreen: 'rgba(17, 34, 17, 0.5)',
    green: '#DBFF00', // lime
    blue: '#0058FF',
    error: '#FF6A56',
  },
};
