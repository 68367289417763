import styled from 'styled-components';

/* INFO: padding-bottom prevents the clipping of the descenders of the text.
 * Seems to add a small enough amount of space not to effect the smaller
 * type sizes. Still probably not ideal.
 */
const Unveiler = styled.span`
  overflow: hidden;
  padding-bottom: 0.1rem;
  display: block;
  > * {
    display: block;
    transition: ${({ theme, speed, delay = 0 }) =>
      `transform ease ${speed || theme.animation.duration}s ${delay}s`};
    transform: ${({ active }) =>
      active ? 'translateY(0%)' : 'translateY(100%)'};
  }
`;

export default Unveiler;
