import { useState, useEffect } from 'react';

const isBrowser = typeof window !== `undefined`;
function getScrollPosition() {
  return isBrowser
    ? { x: window.pageXOffset, y: window.pageYOffset }
    : { x: 0, y: 0 };
}
export default function useScrollPosition() {
  const [position, setScrollPosition] = useState({
    ...getScrollPosition(),
    prevX: getScrollPosition().x,
    prevY: getScrollPosition().y,
  });
  useEffect(() => {
    let requestRunning = null;
    function handleScroll() {
      if (isBrowser && requestRunning === null) {
        requestRunning = window.requestAnimationFrame(() => {
          setScrollPosition((prevScrollPosition) => ({
            ...getScrollPosition(),
            prevX: prevScrollPosition.x,
            prevY: prevScrollPosition.y,
          }));
          requestRunning = null;
        });
      }
    }
    if (isBrowser) {
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
    return null;
  }, []);
  return position;
}
