import PropTypes from 'prop-types';
import * as React from 'react';

function Arrow({ title, titleId, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32.244 31.189"
      aria-labelledby={title ? titleId : null}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g fill="none" stroke="currentColor">
        <path d="M16.296.354l15.241 15.241-15.241 15.241" />
        <path d="M0 15.566h31.706" />
      </g>
    </svg>
  );
}

export default Arrow;

Arrow.propTypes = {
  title: PropTypes.string,
  titleId: PropTypes.string,
};

Arrow.defaultProps = {
  titleId: 'Arrow',
};
