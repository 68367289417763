import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Hr from 'components/common/HorizontalRule';
import { Regular } from 'components/common/Typography';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 4%;
`;

const Link = styled(Regular)`
  color: currentColor;
  opacity: ${({ active }) => (active ? 1 : 0)};
  transition: opacity ${({ theme }) => theme.animation.duration}s ease;
  line-height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

const Border = styled(Hr)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition-delay: ${({ delay }) => `${delay}s`};
`;

const SocialMediaNav = ({ className, items, active }) => (
  <Wrapper className={className}>
    {items.map((lifeSuck, i) => (
      <Link as="a" key={i} href={lifeSuck.url} active={active}>
        <Border active={active} delay={active ? i * 0.05 : 0} />
        {lifeSuck.name}
      </Link>
    ))}
  </Wrapper>
);

SocialMediaNav.propTypes = {
  active: PropTypes.bool.isRequired,
  className: PropTypes.string,
  items: PropTypes.array.isRequired,
};

export default SocialMediaNav;
