import { between as polishedBetween } from 'polished';

export default function fluidRange(
  fromVal,
  toVal,
  from = 'mobileSmall',
  to = 'desktop'
) {
  return function getStyle({ theme }) {
    return polishedBetween(
      fromVal,
      toVal,
      `${theme.breakpoints[from]}px`,
      `${theme.breakpoints[to]}px`
    );
  };
}
