import styled from 'styled-components';

import fluidRange from 'utils/fluidRange';

export const Display = styled.h1`
  font-weight: 300;
  letter-spacing: -0.01em;
  margin: 0;
  color: ${({ color }) => `var(--${color}, currentColor)`};
  ${fluidRange({
    prop: 'line-height',
    fromSize: '30px',
    toSize: '72px',
  })}
  ${fluidRange({
    prop: 'font-size',
    fromSize: '28px',
    toSize: '64px',
  })}
`;

export const Large = styled.h1`
  font-weight: 300;
  letter-spacing: -0.01em;
  margin: 0;
  color: ${({ color }) => `var(--${color}, currentColor)`};
  ${fluidRange({
    prop: 'font-size',
    fromSize: '20px',
    toSize: '32px',
  })}
  ${fluidRange({
    prop: 'line-height',
    fromSize: '23px',
    toSize: '36px',
  })}
`;

export const Regular = styled.p`
  font-weight: 400;
  letter-spacing: -0.01em;
  margin: 0;
  color: ${({ color }) => `var(--${color}, currentColor)`};
  ${fluidRange({
    prop: 'font-size',
    fromSize: '16px',
    toSize: '20px',
  })}
  ${fluidRange({
    prop: 'line-height',
    fromSize: '19px',
    toSize: '25px',
  })}
`;

export const XSmall = styled.p`
  font-weight: 400;
  letter-spacing: -0.01em;
  margin: 0;
  color: ${({ color }) => `var(--${color}, currentColor)`};
  ${fluidRange({
    prop: 'font-size',
    fromSize: '13px',
    toSize: '14px',
  })}
  ${fluidRange({
    prop: 'line-height',
    fromSize: '13px',
    toSize: '14px',
  })}
`;
