/* import AniLink from 'gatsby-plugin-transition-link/AniLink'; */

import { Link } from 'gatsby';

export default function getLinkElement(href, onClick, to) {
  /* if (to) return AniLink; */
  if (to) return Link;
  if (href) return 'a';
  if (onClick) return 'button';
  return 'div';
}
