import React from 'react';
import PropTypes from 'prop-types';

import Unveiler from 'components/common/Unveiler';
import NavLink from './NavLink';

export default function Routes({
  routes,
  duration,
  currentPath,
  active,
  toggleNav,
}) {
  return (
    <>
      {routes.map((route, i) => (
        <Unveiler key={i} active={active} delay={active ? i * 0.1 : 0}>
          <NavLink
            active={route.path === currentPath ? true : null}
            duration={duration || undefined}
            name={route.name}
            to={!route.path.includes('@') ? route.path : undefined}
            href={route.path.includes('@') ? `mailto:${route.path}` : undefined}
            onClick={() => toggleNav(false)}
          />
        </Unveiler>
      ))}
    </>
  );
}

Routes.propTypes = {
  active: PropTypes.bool.isRequired,
  duration: PropTypes.number,
  currentPath: PropTypes.string.isRequired,
  routes: PropTypes.array.isRequired,
  toggleNav: PropTypes.func.isRequired,
};
