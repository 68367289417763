import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import UnstyledButton from 'components/common/UnstyledButton';

const Wrapper = styled(UnstyledButton)`
  height: 48px;
  width: 48px;
  font-size: 0;
  position: relative;
  &:focus {
    outline: none;
  }
`;

const OpenLine = styled.div`
  height: 1px;
  background: currentColor;
  position: absolute;
  right: 13px;
  will-change: opacity, transform;
  opacity: ${({ active }) => (active ? 0.5 : 1)};
  &:nth-child(1) {
    top: 19px;
    width: ${({ active }) => (active ? '0px' : '22px')};
    transition: all ease 0.25s ${({ active }) => (active ? ' 0s' : '.4s')};
  }
  &:nth-child(2) {
    top: 27px;
    width: ${({ active }) => (active ? '0px' : '22px')};
    transition: all ease 0.25s ${({ active }) => (active ? ' .1s' : '.5s')};
  }
`;

const ClosedLine = styled.div`
  height: 1px;
  background: var(--white);
  position: absolute;
  right: 13px;
  will-change: clip-path, opacity;
  opacity: ${({ active }) => (active ? 1 : 0.5)};
  width: 22px;
  top: 22px;
  &:nth-child(3) {
    clip-path: ${({ active }) =>
      active
        ? 'polygon(0 0, 100% 0, 100% 100%, 0 100%)'
        : 'polygon(100% 0, 100% 0, 100% 100%, 100% 100%)'};
    transform: rotate(315deg);
    transition: opacity ease 0.25s ${({ active }) => (active ? ' 0.5s' : '.1s')},
      clip-path ease 0.25s ${({ active }) => (active ? ' 0.5s' : '.1s')};
  }
  &:nth-child(4) {
    clip-path: ${({ active }) =>
      active
        ? 'polygon(0 0, 100% 0, 100% 100%, 0 100%)'
        : 'polygon(100% 0, 100% 0, 100% 100%, 100% 100%)'};
    transform: rotate(-135deg);
    transition: opacity ease 0.25s ${({ active }) => (active ? ' 0.6s' : '0s')},
      clip-path ease 0.25s ${({ active }) => (active ? ' 0.6s' : '0s')};
  }
`;

const DrawerToggle = ({ active, toggle, className }) => (
  <Wrapper
    className={className}
    onClick={toggle}
    aria-label={active ? 'Close the menu' : 'Open the menu'}
  >
    Toggle Menu
    <OpenLine active={active} />
    <OpenLine active={active} />
    <ClosedLine active={active} />
    <ClosedLine active={active} />
  </Wrapper>
);

DrawerToggle.propTypes = {
  active: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default DrawerToggle;
