// store.js
import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { colors } from 'utils/theme';

const initialState = {
  introComplete: false,
  headerColor: colors.black,
  headerBackground: 'transparent',
  footerActive: false,
};

const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((prevState, action) => {
    switch (action.type) {
      case 'headerColor':
        return { ...prevState, headerColor: action.value };
      case 'headerBackground':
        return { ...prevState, headerBackground: action.value };
      case 'heroHeight':
        return { ...prevState, heroHeight: action.value };
      case 'introComplete':
        return { ...prevState, introComplete: action.value };
      case 'introDelaySecs':
        return { ...prevState, introDelaySecs: action.value };
      case 'footerActive':
        return { ...prevState, footerActive: action.value };
      default:
        throw new Error();
    }
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider };

StateProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
