import React from 'react';
import 'focus-visible';
import './src/fonts/fonts.css';
import { ThemeProvider } from 'styled-components';
import theme from 'utils/theme';
import { StateProvider } from './src/context/store';
import Layout from './wrapPageElement';

export const wrapPageElement = Layout;

export const wrapRootElement = ({ element }) => (
  <StateProvider>
    <ThemeProvider theme={theme}>{element}</ThemeProvider>
  </StateProvider>
);

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  /* Half duration of transition animation */
  const TRANSITION_DELAY = theme.animation.duration * 500;

  if (location.action === 'PUSH') {
    window.setTimeout(() => window.scrollTo(0, 0), TRANSITION_DELAY);
  } else {
    const savedPosition = getSavedScrollPosition(location) || [0, 0];

    window.setTimeout(
      () => window.scrollTo(...savedPosition),
      TRANSITION_DELAY
    );
  }

  return false;
};
