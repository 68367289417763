import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { minWidth, maxWidth } from 'utils/breakpoints';
import Link from 'components/common/Link';

const Wrapper = styled(Link)`
  color: currentColor;
  position: relative;
  ${maxWidth('tabletLandscape')} {
    font-size: 44px;
    line-height: 1.2em;
    display: inline-block;
  }
  ${minWidth('tabletLandscape')} {
    display: block;
    &:before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      height: 1px;
      background: currentColor;
      width: 0%;
      transition: width ${({ theme }) => theme.animation.delay}s ease-out
        ${({ active }) => (active ? 1 : 0)}s;
    }
    ${({ active }) =>
      active &&
      css`
        @media (min-width: 600px) {
          &:before {
            width: 100%;
          }
        }
      `}
  }
`;

export default function NavLink({
  active,
  to,
  duration,
  href,
  name,
  onClick,
  className,
}) {
  return (
    <Wrapper
      active={active}
      duration={duration}
      onClick={onClick}
      to={to}
      href={href}
      className={className}
    >
      {name}
    </Wrapper>
  );
}

NavLink.propTypes = {
  active: PropTypes.bool,
  duration: PropTypes.number,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  to: PropTypes.string,
  href: PropTypes.string,
  className: PropTypes.string,
};
