import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';

import getLinkElement from 'utils/getLinkElement';
import UnstyledButton from 'components/common/UnstyledButton';

const Wrapper = styled(UnstyledButton)``;

function Link({ className, children, onClick, to, href, disabled }) {
  return (
    <Wrapper
      className={className}
      as={disabled ? 'div' : getLinkElement(href, onClick, to)}
      href={href}
      onClick={disabled ? undefined : onClick}
      to={disabled ? undefined : to}
    >
      {children}
    </Wrapper>
  );
}

export default withTheme(Link);

Link.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  to: PropTypes.string,
  disabled: PropTypes.bool,
};

Link.defaultProps = {
  disabled: false,
};
