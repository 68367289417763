import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { useTheme } from 'styled-components';

import Grid from 'components/common/Grid';
import { Large } from 'components/common/Typography';

import { fadeIn } from 'utils/keyframes';
import fluidRange from 'utils/fluidRange';

import { store } from 'context/store';

const Wrapper = styled(Grid)`
  position: ${({ position }) => position};
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 9999;
  background: var(--black);
  color: var(--white);
  align-items: center;
  -webkit-clip-path: polygon(
    ${({ active }) =>
      active ? `0 0, 100% 0, 100% 100%, 0% 100%` : `0 0, 100% 0, 100% 0, 0 0`}
  );
  clip-path: polygon(
    ${({ active }) =>
      active ? `0 0, 100% 0, 100% 100%, 0% 100%` : `0 0, 100% 0, 100% 0, 0 0`}
  );
  -webkit-transition: opacity 0.1s 0.4s, -webkit-clip-path 0.4s ease-in-out;
  transition: opacity 0.1s 0.4s, -webkit-clip-path 0.4s ease-in-out;
  -o-transition: clip-path 0.4s ease-in-out, opacity 0.1s 0.4s;
  transition: clip-path 0.4s ease-in-out, opacity 0.1s 0.4s;
  transition: clip-path 0.4s ease-in-out, opacity 0.1s 0.4s,
    -webkit-clip-path 0.4s ease-in-out;
`;

const FadingText = styled(Large)`
  justify-self: center;
  width: 100%;
  grid-column: col 1 / span 12;
  width: auto;
  ${fluidRange({
    prop: 'font-size',
    fromSize: '18px',
    toSize: '24px',
  })}
`;

const Word = styled.span`
  opacity: 0;
  animation: ${({ animation }) => animation};
  animation-delay: ${({ delay }) => delay}s;
  animation-duration: ${({ duration }) => duration}s;
  animation-timing-function: cubic-bezier(0.89, 0, 0, 1);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  font-weight: ${({ fontWeight }) => fontWeight};
`;

function Intro({ path }) {
  const theme = useTheme();
  const isHomepage = path === '/';
  const { dispatch } = useContext(store);
  const [active, setActive] = useState(true);
  const keyframeDuration = theme.animation.duration * 0.65;
  const text = ['BOLD VISION. ', 'New Futures.'];
  const [wrapperPosition, setWrapperPosition] = useState('fixed');

  const totalDuration = (text.length + 1) * keyframeDuration;

  useEffect(() => {
    if (!isHomepage) setActive(false);
  }, [isHomepage]);

  useEffect(() => {
    if (!isHomepage) dispatch({ type: 'introComplete', value: true });
    setTimeout(() => setActive(false), totalDuration * 1000);
    dispatch({ type: 'introDelaySecs', value: totalDuration });
  }, [dispatch, isHomepage, keyframeDuration, totalDuration]);

  const handleAnimationEnd = () => {
    /* Set position to absolute to prevent iOS touchscroll bug */
    setWrapperPosition('absolute');
    dispatch({ type: 'introComplete', value: true });
  };

  return (
    <Wrapper
      active={active}
      position={wrapperPosition}
      onTransitionEnd={handleAnimationEnd}
    >
      <FadingText>
        {text.map((word, i) => (
          <Word
            key={`word-${i}`}
            animation={fadeIn}
            duration={keyframeDuration}
            delay={i * keyframeDuration}
            fontWeight={i === 0 ? '700' : '100'}
          >
            {word}
          </Word>
        ))}
      </FadingText>
    </Wrapper>
  );
}

export default Intro;

Intro.propTypes = {
  path: PropTypes.string.isRequired,
};
