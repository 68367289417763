function invariant(condition, message) {
  if (!condition) {
    throw new Error(message);
  }
}

export function maxWidth(breakpoint) {
  return function getStyle({ theme }) {
    const width = theme.breakpoints[breakpoint];
    invariant(width, `Breakpoint "${breakpoint}" does not exist in theme`);
    return `@media (max-width: ${width - 1}px)`;
  };
}

export function minWidth(breakpoint) {
  return function getStyle({ theme }) {
    const width = theme.breakpoints[breakpoint];
    invariant(width, `Breakpoint "${breakpoint}" does not exist in theme`);
    return `@media (min-width: ${width}px)`;
  };
}

export function minHeight(breakpoint) {
  return function getStyle({ theme }) {
    const height = theme.breakpoints[breakpoint];
    invariant(height, `Breakpoint "${breakpoint}" does not exist in theme`);
    return `@media (min-height: ${height}px)`;
  };
}

export function maxHeight(breakpoint) {
  return function getStyle({ theme }) {
    const height = theme.breakpoints[breakpoint];
    invariant(height, `Breakpoint "${breakpoint}" does not exist in theme`);
    return `@media (max-height: ${height - 1}px)`;
  };
}
