import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Hr = styled.hr`
  transform: ${({ active }) => (active ? 'scaleX(1)' : 'scaleX(0)')};
  transform-origin: left;
  height: 1px;
  background: currentColor;
  border: 0;
  transition: transform ${({ theme }) => theme.animation.duration}s
    cubic-bezier(0.165, 0.84, 0.44, 1) ${({ delay }) => delay}s;
  margin: 0;
  grid-column: main;
  will-change: transform;
`;

const HorizontalRule = ({ className, active, delay = 0 }) => (
  <Hr className={className} active={active} delay={delay} />
);

HorizontalRule.propTypes = {
  active: PropTypes.bool,
  delay: PropTypes.number,
  className: PropTypes.string,
};

HorizontalRule.defaultProps = {
  active: true,
};

export default HorizontalRule;
