import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import styled, { css } from 'styled-components';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import tinycolor from 'tinycolor2';
import Button from 'components/common/Button';
import Grid from 'components/common/Grid';
import Link from 'components/common/Link';
import Unveiler from 'components/common/Unveiler';
import useScrollPosition from 'hooks/useScrollPosition';
import { minWidth, maxWidth } from 'utils/breakpoints';
import fluidRange from 'utils/fluidRange';
import useBreakpoint from 'hooks/useBreakpoint';
import { store } from 'context/store';
import between from 'utils/between';
import Routes from './Routes';
import DrawerToggle from './DrawerToggle';
import SocialMedia from './SocialMediaNav';
import { useElementSize } from '../../../hooks/useElementSize';

const Wrapper = styled.header`
  left: 0;
  top: 0;
  width: 100%;
  z-index: 20;
  color: ${({ color }) => color};
  background: ${({ background, navActive }) =>
    navActive ? 'var(--black)' : background};
  position: fixed;
  transition: transform ease ${({ theme }) => theme.animation.duration / 2}s,
    background ease ${({ theme }) => theme.animation.duration / 2}s;
  ${maxWidth('tabletLandscape')} {
    transition-delay: ${({ theme, navActive }) =>
      navActive ? 0 : theme.animation.duration / 2}s;
  }
  ${({ heroContentScrolled }) =>
    heroContentScrolled &&
    css`
      transform: translateY(${({ showNavBar }) => (showNavBar ? 0 : '-100%')});
    `}
`;

const Inner = styled(Grid)`
  grid-column: main;
  align-items: center;
  ${fluidRange({
    prop: 'height',
    fromSize: '48px',
    toSize: '68px',
  })}
`;

const Logo = styled(Link)`
  position: relative;
  color: currentColor;
  display: block;
  grid-column: col 1 / 4;
  justify-self: start;
  ${maxWidth('tabletLandscape')} {
    z-index: 10;
    color: ${({ active, color }) => (active ? 'var(--white)' : color)};
    transition: color ease
      ${({ theme, active }) =>
        `${theme.animation.duration / 2}s ${
          active ? 0 : theme.animation.duration / 2
        }s`};
  }
`;

const DesktopNav = styled.nav`
  display: inline-grid;
  grid-auto-flow: column;
  grid-column: col 5 / 12;
  justify-self: start;
  ${maxWidth('tabletLandscape')} {
    display: none;
  }
  ${fluidRange({
    prop: 'grid-column-gap',
    fromSize: '40px',
    toSize: '60px',
  })}
`;

const MobileNav = styled.nav`
  ${maxWidth('tabletLandscape')} {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    pointer-events: ${({ active }) => (active ? 'initial' : 'none')};
    opacity: ${({ active }) => (active ? 1 : 0)};
    transition: opacity ${({ theme }) => theme.animation.duration / 2}s ease
      ${({ active, theme }) =>
        active ? `0s` : `${theme.animation.duration / 2}s`};
    color: var(--white);
    background: var(--black);
    padding: ${between('10px', '20px')};
    display: grid;
    grid-template-rows: 1fr auto;
  }
  ${minWidth('tabletLandscape')} {
    display: none;
  }
`;

const MobileLinks = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Contact = styled(Button)`
  font-weight: 400;
  grid-column: col 11 / 14;
  justify-self: end;
  ${maxWidth('tabletLandscape')} {
    display: none;
  }
`;

const Toggle = styled(DrawerToggle)`
  ${maxWidth('tabletLandscape')} {
    grid-column: 7 / 14;
    justify-self: end;
    transform: translateX(14px);
  }
  ${minWidth('tabletLandscape')} {
    display: none;
  }
`;

export default function Header({ currentPath }) {
  const data = useStaticQuery(graphql`
    query {
      contact: sanityContact {
        instagram
        facebook
        linkedIn
        email
      }
    }
  `);

  const routes = [
    { name: 'Work', path: '/work/' },
    { name: 'Studio', path: '/studio/' },
    { name: 'Programs', path: '/programs/' },
    { name: 'Ventures', path: '/ventures/' },
    { name: 'News', path: '/news/' },
  ];

  const mobileNavRef = useRef(null);
  const [loaded, setLoaded] = useState(false);
  const [navIsActive, setNavIsActive] = useState(false);
  const isDesktop = useBreakpoint('tabletLandscape');
  const [headerRef, { height: headerHeight }] = useElementSize();
  const { y: yOffset, prevY: prevYOffset } = useScrollPosition();
  const { heroHeight, headerColor, headerBackground, footerVisible } =
    useContext(store).state;
  const heroContentScrolled = yOffset > heroHeight - headerHeight;
  const scrollingUp = prevYOffset > yOffset;
  const ctaColor = tinycolor(headerColor).isDark() ? 'black' : 'white';

  const [showNavBar, setShowNavBar] = useState(false);

  useEffect(() => {
    setTimeout(() => setLoaded(true), 0);
  }, []);

  useEffect(() => {
    if (navIsActive && !isDesktop) {
      disableBodyScroll(mobileNavRef);
    } else {
      enableBodyScroll(mobileNavRef);
    }
  }, [navIsActive, isDesktop]);

  useEffect(() => {
    if (footerVisible) {
      setShowNavBar(false);
      return;
    }
    if (scrollingUp || navIsActive) {
      setShowNavBar(true);
      return;
    }
    setShowNavBar(false);
  }, [scrollingUp, navIsActive, footerVisible]);

  function toggleNav(bool) {
    setNavIsActive((prevState) =>
      typeof bool === 'boolean' ? bool : !prevState
    );
  }

  return (
    <>
      <Wrapper
        color={headerColor}
        background={headerBackground}
        ref={headerRef}
        active={navIsActive}
        navActive={navIsActive}
        heroContentScrolled={heroContentScrolled}
        showNavBar={showNavBar}
      >
        <Inner>
          <Logo
            color={headerColor}
            active={navIsActive}
            to="/"
            onClick={() => toggleNav(false)}
            duration={isDesktop && navIsActive ? 0.1 : undefined}
          >
            <Unveiler active={loaded}>
              <span>
                Justified&nbsp;Studio
                {/* <img src="/logo.svg" alt="Justified Studio" /> */}
              </span>
            </Unveiler>
          </Logo>
          <Toggle active={navIsActive} toggle={toggleNav} />
          <DesktopNav>
            <Routes
              currentPath={currentPath}
              routes={routes}
              active={loaded}
              toggleNav={toggleNav}
            />
          </DesktopNav>
          <Contact
            color={ctaColor}
            href={`mailto:${data.contact.email}`}
            size="medium"
          >
            Contact
          </Contact>
        </Inner>
      </Wrapper>
      <MobileNav ref={mobileNavRef} active={navIsActive}>
        <MobileLinks>
          <Routes
            currentPath={currentPath}
            routes={routes}
            active={navIsActive}
            toggleNav={toggleNav}
            duration={0.1}
          />
        </MobileLinks>
        <SocialMedia
          active={navIsActive}
          items={[
            { name: 'Email', url: `mailto:${data.contact.email}` },
            {
              name: 'Instagram',
              url: `https://www.instagram.com/${data.contact.instagram}`,
            },
            {
              name: 'Facebook',
              url: `https://www.instagram.com/${data.contact.facebook}`,
            },
            {
              name: 'LinkedIn',
              url: `https://www.instagram.com/${data.contact.linkedIn}`,
            },
          ]}
        />
      </MobileNav>
    </>
  );
}

Header.propTypes = {
  currentPath: PropTypes.string.isRequired,
};
